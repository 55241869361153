import dsdInstance from "./dsdInstance";

export default class UserService {

    static confirmUsers = username => {
        return dsdInstance.post(
            "/user/adminConfirm",
            {username: username},
        );
    };

    static searchUsers = (term) => {
        return dsdInstance.get("user/search/" + term);
    };

    static fetchUsers = () => {
        return dsdInstance.post(
            "/user/getAll",
            {}
        );
    };

    static fetchUser = id => {
        return dsdInstance.get("/user/getUser/" + id);
    };

    static updateUser = data => {
        return dsdInstance.put("user/updateUser", data);
    };

    static deleteUser = data => {
        return dsdInstance.delete("/user/deleteUser", {data: data});
    };

//Add to and from date parameters to url endpoint here.
    static getUserDetails = (id, endDate, startDate, lastKey) => {
        const limit = 15;

        //Define if parameters exist here. If not, set them equal to an empty string.
        if (lastKey && startDate && endDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&last_key=" + lastKey + "?&to_date=" + endDate + "&from_date=" + startDate);
        } else if (lastKey && startDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&last_key=" + lastKey + "&from_date=" + startDate);
        } else if (lastKey && endDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&last_key=" + lastKey + "?&to_date=" + endDate);
        } else if (lastKey) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&last_key=" + lastKey);
        } else if (startDate && endDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&to_date=" + endDate + "&from_date=" + startDate);
        } else if (startDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&from_date=" + startDate);
        } else if (endDate) {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit + "?&to_date=" + endDate);
        } else {
            return dsdInstance.get("/event/get/" + id + "?limit=" + limit);
        }

    };
}