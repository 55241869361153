import React, {useEffect, useState} from 'react';
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardAvatar from "../../../components/Card/CardAvatar";
import avatar from "assets/img/doctor-avetar-ash.png";
import CardFooter from "../../../components/Card/CardFooter";
import Badge from "../../../components/Badge/Badge";
import Moment from "react-moment";
import {Dialog, DialogContent, IconButton} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";
import {SHOW_APP_ALERT} from "../../../action-types";
import {useDispatch} from "react-redux";
import userService from "../../../services/userService";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import AppUserForm from "./AppUserForm";
import AppUserModel from "../../../Modals/AppUser.model";
import UserDevices from "./UserDevices";

const statusColor = {
    UNCONFIRMED: 'danger',
    CONFIRMED: 'success',
}
const useStyles = makeStyles(styles);

export default function UserProfile(props) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const {Username, email, name, isConfirmed} = props;
    const [editModal, setEditModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(new AppUserModel({Email: email, Username: Username, Name: name}));
    useEffect(() => {
        if(Username && isConfirmed) {
            getCurrentUser();
        }
    }, [Username])
    const deleteProfile = async () => {
        await userService.deleteUser({id: Username, username: email})
        let config = {
            type: "success",
            message: 'User Deleted Successfully'
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
        props.resetSearch();
    }
    const getCurrentUser = async () => {
        const userRes = await userService.fetchUser(Username);
        try {
            const user = new AppUserModel(userRes.data.data.result);
            setCurrentUser(user);
        }
        catch (e) {
            console.error(e);
        }
    }
    const deleteConfirmation = () => {
        let config = {
            type: "confirmation",
            message: 'Do you want to delete this user?',
            onConfirm: deleteProfile
        };
        dispatch({ type: SHOW_APP_ALERT, alertConfig: config });
    }
    return (
            <Card profile style={{minHeight: '308px'}}>
                <CardAvatar profile>
                    <a onClick={e => e.preventDefault()}>
                        <img src={avatar} alt="..." />
                    </a>
                </CardAvatar>
                <CardBody profile>
                    <h5>{currentUser.Name} {currentUser.FamilyName}</h5>
                    <p><b>{currentUser.Email}</b></p>
                    <p>{currentUser.PhoneNumber}</p>
                    <p>Joined at <Moment format={"YYYY-MM-DD HH:MM:SS"}>{props.UserCreateDate}</Moment></p>
                    <p>{currentUser.Country} | {currentUser.Language}</p>
                    <UserDevices {...currentUser.devices}/>
                    <Badge color={statusColor[props.UserStatus]}>{props.UserStatus}</Badge>
                    <div>
                        <IconButton size={"small"} onClick={deleteConfirmation} disabled={!isConfirmed}>
                            <Delete fontSize={"small"}/>
                        </IconButton>
                        <IconButton size={"small"} onClick={() =>{setEditModal(true)}} disabled={!isConfirmed}>
                            <Edit fontSize={"small"}/>
                        </IconButton>
                    </div>
                </CardBody>
                <CardFooter>
                </CardFooter>
                <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={editModal}
                    keepMounted
                    onClose={() => setEditModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogContent >
                        <AppUserForm
                            currentUser={currentUser}
                            setEditModal={(e) => {setEditModal(e)}}
                            getCurrentUser={() => {getCurrentUser()}}/>
                    </DialogContent>
                </Dialog>

            </Card>
    )
}