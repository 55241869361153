import React, {useEffect, useState} from 'react';
import AppIntegrationService from "../../services/AppIntegrationService";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import IntegrationCard from "./Cmps/IntegrationCard";
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import IntegrationFom from "./Cmps/IntegrationFom";
import Card from "../../components/Card/Card";
import RegularButton from "../../components/CustomButtons/RegularButton";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {Add, AddBox} from "@material-ui/icons";

const useStyles = makeStyles(modalStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function AppIntegrations(props) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [forEdit, setForEdit] = useState(null);

    useEffect(() => {
        loadIntegrations();
    }, [])

    useEffect(() => {
        if(forEdit) {
            setOpenForm(true);
        }
    },[forEdit])
    const loadIntegrations = async () => {
        const res = await AppIntegrationService.getAll();
        setItems(res);
    }

    const loadCards = () => {
        if (items) {

            return items.map(item => {
                return <GridItem md={3}>
                    <IntegrationCard item={item} key={item.Id} setForEdit={(d) => setForEdit(d)} loadIntegrations={loadIntegrations}/>
                </GridItem>
            });
        }
    }
    return <GridContainer>
        <GridItem md={12}>
               <RegularButton
                   justIcon
                   round
                   color="success"
                   // className={classes.floatRight}
                   onClick={() => {
                       setOpenForm(true);
                   }}
               >
                   <Add className={classes.icons} />
               </RegularButton>
        </GridItem>
        {
            loadCards()
        }
        <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={openForm}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenForm(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => setOpenForm(false)}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>App integration form</h4>
            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
                <IntegrationFom setOpenForm={(b)=>setOpenForm(b)} reFresh={() => loadIntegrations()} open = {openForm} forEdit={forEdit}/>
            </DialogContent>
        </Dialog>
    </GridContainer>
}